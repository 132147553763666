export const ArrowLeftIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 14 14'
    >
      <path
        fillRule='evenodd'
        d='M9.69 11.805c.377-.287.415-.786.086-1.114L6.355 7.283a.4.4 0 010-.566l3.42-3.408c.33-.328.292-.827-.084-1.114-.377-.287-.95-.254-1.279.075L4.254 6.41a.81.81 0 000 1.18l4.158 4.14c.33.329.902.362 1.279.075z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export const ArrowRightIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.31 2.195c-.377.287-.415.786-.086 1.114l3.421 3.408a.4.4 0 010 .566l-3.42 3.408c-.33.328-.292.826.084 1.114.377.287.95.254 1.279-.075l4.158-4.14a.81.81 0 000-1.18L5.588 2.27c-.33-.329-.902-.362-1.279-.075z'
      />
    </svg>
  );
};

export const ArrowUpIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 14 14'
    >
      <path
        fillRule='evenodd'
        d='M2.195 9.69c.287.377.786.415 1.114.086l3.408-3.421a.4.4 0 01.566 0l3.408 3.42c.328.33.826.292 1.114-.084.287-.377.254-.95-.075-1.279L7.59 4.254a.81.81 0 00-1.18 0L2.27 8.412c-.329.33-.362.902-.075 1.279z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};

export const ArrowDownIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 14 14'
    >
      <path
        fillRule='evenodd'
        d='M11.805 4.31c-.287-.377-.786-.415-1.114-.086L7.283 7.645a.4.4 0 01-.566 0l-3.408-3.42c-.328-.33-.827-.292-1.114.084-.287.377-.254.95.075 1.279l4.14 4.158a.81.81 0 001.18 0l4.14-4.158c.329-.33.362-.902.075-1.279z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
};
